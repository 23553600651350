<template>
  <v-card
    min-width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    class="px-2 mx-auto"
  >
    <v-toolbar elevation="0">
      <v-row justify="center">
        <span class="text-h5">BILLBOARD FORM</span>
      </v-row>
    </v-toolbar>

    <v-progress-linear
      color="deep-purple accent-4"
      :active="loading"
      :indeterminate="loading"
    ></v-progress-linear>

    <v-card-text class="pt-0">
      <v-form v-model="valid" ref="form">
        <v-container fluid>
          <v-row justify="center" v-if="currentBillboard.id">
            <v-col v-for="(img, idx) in currentBillboard.images" :key="idx">
              <div v-viewer v-if="currentBillboard.images[0].path">
                <v-card>
                  <v-card-text class="pt-0">
                    <v-row align="center" justify="center">
                      <img
                        alt="Billboard Image"
                        class="previewImage"
                        :src="img.path"
                        :lazy-src="img.path"
                      />
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-icon
                      color="error"
                      title="Delete"
                      @click="deleteItem(img.id)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-card-actions>
                </v-card>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <handy-uploader
              :documentAttachment.sync="images64"
              :fileUploaderType="'table'"
              :imageCompressor="true"
              :maxFileCount="5"
              :maxFileSize="10240"
              :badgeCounter="true"
              :cardType="'raised'"
              :fileAccept="'image/png,image/gif,image/jpeg,image/webp'"
              :addFileDescription="true"
              :thumb="true"
              :tableFixedHeader="true"
              :tableHeight="200"
              :tableWidth="400"
              :tableThumbColumn="true"
            ></handy-uploader>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentBillboard.rate_card"
                type="number"
                min="0"
                suffix="R"
                label="Price"
                :rules="rules.genRule"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-row class="mt-2" align="center" justify="center">
                <gmap-autocomplete
                  placeholder="Location of Billboard"
                  required
                  id="address"
                  class="v-text-field v-text-field-outlined"
                  @place_changed="setPlace"
                  :options="autoCompleteBounds"
                ></gmap-autocomplete>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                type="number"
                label="Longitude"
                v-model="currentBillboard.longitude"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                type="number"
                label="Latitude"
                v-model="currentBillboard.latitude"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :rules="rules.selectRule"
                :items="lightingChoices"
                v-model="currentBillboard.lighting"
                label="Lighting"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :rules="rules.selectRule"
                :items="mediumChoices"
                v-model="currentBillboard.medium"
                label="Medium"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :rules="rules.selectRule"
                :items="orientationChoices"
                v-model="currentBillboard.orientation"
                label="Orientation"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :rules="rules.selectRule"
                :items="sideOfRoadChoices"
                v-model="currentBillboard.side_of_road"
                label="Side of Road"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentBillboard.site_run_up"
                :rules="rules.site_run_upRules"
                required
                type="number"
                label="Site Run Up"
                suffix="Metres"
                placeholder="Maximum distance the Billboard is visible"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentBillboard.faces"
                :rules="rules.faceRules"
                type="number"
                label="Faces"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6"
              ><v-select
                :rules="rules.selectRule"
                :items="clutterChoices"
                v-model="currentBillboard.clutter"
                label="Clutter"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                :rules="rules.selectRule"
                :items="heightChoices"
                v-model="currentBillboard.height"
                label="Height"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentBillboard.length"
                :rules="rules.genRule"
                label="Length"
                suffix="Metres"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.trim="currentBillboard.width"
                :rules="rules.genRule"
                label="Width"
                suffix="Metres"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="submitForm"> Save </v-btn>
    </v-card-actions>

    <alert></alert>
  </v-card>
</template>

<script>
import handyUploader from "handy-uploader/src/components/handyUploader";

import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("billboards");
import { handleHttpError } from "@/helpers/utils";

export default {
  data() {
    return {
      images64: [],
      image_id: null,

      loading: false,
      dialog: false,
      valid: false,
      rules: {
        imageRules: [(v) => !!v || "Image is required"],
        selectRule: [(v) => !!v || "Please Select an Option"],
        genRule: [(v) => !!v || "Please fill this."],
        site_run_upRules: [
          (v) => !!v || "Required",
          (v) => v >= 0 || "Distance should be above 0",
          (v) => v <= 200 || "Max should not be above 200",
        ],
        faceRules: [
          (v) => !!v || "Required",
          (v) => v >= 0 || "Faces should be above 0",
          (v) => v <= 4 || "Max should not be above 4",
        ],
      },
      heightChoices: ["Eye Level", "Moderate", "High"],
      clutterChoices: ["Solus", "Average", "Cluttered", "Solus"],
      lightingChoices: ["Frontlit", "Backlit", "Unlit", "LED"],
      mediumChoices: [
        "Wall Wrap",
        "RoadArch/Gantry",
        "Rooftops",
        "LED",
        "Unipole",
        "Medium",
        "Bus Stop",
        "Bus Stop Shelter",
      ],
      orientationChoices: ["Landscape", "Portrait", "Square"],
      sideOfRoadChoices: ["Left", "Middle", "Right"],
      industryChoices: [
        "None",
        "Agriculture",
        "Forestry",
        "Fishing & Hunting",
        "Mining",
        "Utilities",
        "Construction",
        "Manufacturing",
        "Wholesale Trade",
        "Retail Trade",
        "Transportation and Warehousing",
        "Real Estate and Rental and Leasing",
        "Professional, Scientific and Technical Services",
        "Educational Services",
      ],

      deleteDialog: false,

      autoCompleteBounds: {
        bounds: {
          north: 11.234926,
          south: 4.737842,
          east: 1.201117,
          west: -3.265621,
        },
        strictBounds: true,
      },
    };
  },

  props: {
    currentBillboard: {
      type: Object,
      default: () => ({
        images: [],
        industry: null,
        site_run_up: null,
        faces: null,
        length: null,
        width: null,
        height: null,
        clutter: null,
        lighting: null,
        medium: null,
        orientation: null,
        side_of_road: null,
        address: null,
        latitude: 0,
        location: null,
        longitude: 0,
        rate_card: null,
        brand_name: null,
        direction: null,
        id: null,
        owner_id: null,
        status: true,
        bb_id: null,
        supplier: null,
      }),
    },
    operation: String,
  },
  components: { handyUploader },

  methods: {
    ...mapActions(["addBillboards", "updateBillboard"]),

    deleteItem(id) {
      this.image_id = id;
      this.deleteDialog = true;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    deleteImageConfirm() {
      this.deleteImage();
      this.closeDelete();
      this.loading = false;
    },
    deleteImage() {
      const rmIdx = this.currentBillboard.images.findIndex(
        (img) => img.id === this.image_id
      );
      this.currentBillboard.images.splice(rmIdx, 1);
    },

    close() {
      this.dialog = false;
    },

    async submitForm() {
      if (!this.currentBillboard.address) {
        handleHttpError(`Error Billboard does not have an Address.`);
      }
      this.$refs.form.validate();

      if (!this.valid) return;

      this.loading = true;
      const payload = { ...this.currentBillboard, newImages: this.images64 };

      if (this.operation === "edit") {
        if (!this.currentBillboard.images.length) {
          handleHttpError(`Error Billboard does not have an Image.`);
          this.loading = false;
          return;
        }
        await this.updateBillboard(payload);
      } else {
        if (!this.images64.length) {
          handleHttpError(`Error Billboard does not have an Image.`);
          this.loading = false;

          return;
        }
        await this.addBillboards(payload);
      }

      this.loading = false;

      // reset
      this.$refs.form.reset();
    },

    async setPlace(place) {
      if (!place) return;
      this.currentBillboard.address = place.formatted_address;
      this.currentBillboard.latitude = place.geometry.location.lat();
      this.currentBillboard.longitude = place.geometry.location.lng();
    },
  },
};
</script>

<style scoped>
#create {
  background-color: #66bb6a;
  color: white;
  padding: 7px;
}

#edit {
  color: #66bb6a;
}
.previewImage {
  height: 150px;
  cursor: pointer;
}
.previewImage:hover {
  transform: scale(1.05);
  transition: all 0.2s;
}
#address {
  width: 96%;
  padding: 0 5px 0 10px;
  min-height: 3.2em;
  border-bottom: 1px solid grey;
}
</style>
