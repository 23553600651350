var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-5 px-2 mx-auto",attrs:{"min-width":"100%","min-height":"650","id":"bus","elevation":"0"}},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Current Billboards")])],1),_c('v-data-table',{staticClass:"pa-2",attrs:{"items":_vm.getBillboards,"headers":_vm.headers,"search":_vm.search,"sort-by":"name","loading-text":"Fetching Billboards... Please wait","no-data-text":"User does not have Billboards"},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var _ = ref._;
var index = ref.index;
return [_vm._v(" "+_vm._s(_)+" "+_vm._s(index + 1)+" ")]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"images"},_vm._l((item.images),function(img){return _c('img',{key:img.id,staticClass:"image",attrs:{"src":img.path,"lazy-src":img.path}})}),0)])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"actions--icon",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();(_vm.currentBillboard = item), (_vm.showBbForm = true)}}},[_vm._v(" mdi-pencil")]),_c('v-icon',{staticClass:"actions--icon",attrs:{"color":"error","title":"Delete"},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('template',{slot:"top"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"expansionPanels"},on:{"click":_vm.fetchMyBbs}},[_vm._v(" Refresh "),_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-spacer'),_c('v-responsive',{attrs:{"max-width":"90%"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","full-width":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","active":_vm.loading,"indeterminate":_vm.loading}}),_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this Billboard?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteBbConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)],1)],2),_c('v-dialog',{attrs:{"width":"80%"},model:{value:(_vm.showBbForm),callback:function ($$v) {_vm.showBbForm=$$v},expression:"showBbForm"}},[_c('v-toolbar',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showBbForm = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('billboard-form',{attrs:{"operation":"edit","currentBillboard":_vm.currentBillboard}})],1)],1),_c('alert')],1)}
var staticRenderFns = []

export { render, staticRenderFns }