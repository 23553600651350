<template>
  <v-container fluid>
    <v-row justify="center" align="center" class="controller__options pb-2">
      <p>VIEW:</p>

      <v-btn
        v-for="btn in buttons"
        elevation="0"
        :key="btn.name"
        @click="setComponent(btn.cmp)"
        :class="{ active: btn.cmp === currentComponent }"
      >
        <v-icon color="black">{{ btn.icon }}</v-icon>
        {{ btn.name }}
      </v-btn>
    </v-row>

    <div>
      <keep-alive>
        <component :is="currentComponent"></component>
      </keep-alive>
    </div>

    <alert></alert>
  </v-container>
</template>

<script>
import OwnerBblist from "./OwnerBblist.vue";
import OwnerBbmap from "./OwnerBbmap.vue";

import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("billboards");

export default {
  data() {
    return {
      billboards: [],
      currentComponent: "OwnerBblist",
      buttons: [
        {
          name: "Table",
          icon: "mdi-table-large",
          cmp: "OwnerBblist",
        },
        { name: "Map", icon: "mdi-map-legend", cmp: "OwnerBbmap" },
      ],
    };
  },
  components: { OwnerBblist, OwnerBbmap },

  methods: {
    ...mapActions(["fetchMyBbs"]),
    setComponent(cmp) {
      this.currentComponent = cmp;
    },
  },

  async mounted() {
    await this.fetchMyBbs();
  },
};
</script>

<style scoped>
* {
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
}
.container {
  height: 100%;
  width: 100%;
}
.active {
  background: #f68625 !important;
}
.v-btn {
  margin: 15px 10px;
  text-transform: none;
}

.controller__options p {
  margin: 0;
  padding-right: 10px;
}
</style>
