<template>
  <v-card
    min-width="100%"
    min-height="650"
    id="bus"
    elevation="0"
    class="py-5 px-2 mx-auto"
  >
    <v-toolbar flat class="mb-2">
      <v-toolbar-title>Current Billboards</v-toolbar-title>
    </v-toolbar>

    <v-data-table
      :items="getBillboards"
      :headers="headers"
      :search="search"
      sort-by="name"
      class="pa-2"
      loading-text="Fetching Billboards... Please wait"
      no-data-text="User does not have Billboards"
    >
      <template slot="top">
        <v-toolbar flat>
          <v-btn color="expansionPanels" @click="fetchMyBbs">
            Refresh
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-responsive max-width="90%">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              full-width
            ></v-text-field>
          </v-responsive>

          <v-dialog v-model="deleteDialog" max-width="500px">
            <v-card>
              <v-progress-linear
                color="deep-purple accent-4"
                :active="loading"
                :indeterminate="loading"
              ></v-progress-linear>

              <v-card-title class="text-h5"
                >Are you sure you want to delete this Billboard?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteBbConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:[`item.number`]="{ _, index }">
        {{ _ }} {{ index + 1 }}
      </template>

      <template v-slot:[`item.images`]="{ item }">
        <div class="p-2">
          <div v-viewer class="images">
            <img
              class="image"
              v-for="img in item.images"
              :key="img.id"
              :src="img.path"
              :lazy-src="img.path"
            />
          </div>
        </div>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          class="actions--icon"
          color="green"
          @click.stop="(currentBillboard = item), (showBbForm = true)"
        >
          mdi-pencil</v-icon
        >

        <v-icon
          class="actions--icon"
          color="error"
          title="Delete"
          @click="deleteItem(item.id)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="showBbForm" width="80%">
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn icon @click="showBbForm = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card elevation="0">
        <billboard-form
          operation="edit"
          :currentBillboard="currentBillboard"
        ></billboard-form>
      </v-card>
    </v-dialog>
    <alert></alert>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("billboards");
import BillboardForm from "@/components/media_owner/OwnerBbform.vue";

export default {
  data() {
    return {
      search: "",
      loading: false,
      deleteDialog: false,
      dialog: false,

      billboard_id: null,
      showBbForm: false,
      currentBillboard: null,

      headers: [
        { text: "#", align: "start", sortable: false, value: "number" },

        {
          text: "Image",
          sortable: false,
          value: "images",
        },
        { text: "Price (R)", value: "rate_card" },
        { text: "Located At", value: "address" },
        { text: "Lighting", value: "lighting" },
        { text: "Length (m)", value: "length" },
        { text: "Width (m)", value: "width" },
        { text: "Site Run Up(m)", value: "site_run_up" },
        { text: "Orientation", value: "orientation" },
        { text: "Height", value: "height" },
        { text: "Clutter", value: "clutter" },
        { text: "Medium", value: "medium" },
        { text: "Added On", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  components: { BillboardForm },

  computed: {
    ...mapGetters(["getBillboards"]),
  },

  methods: {
    ...mapActions(["fetchMyBbs", "deleteBB"]),

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.deleteDialog = false;
    },

    deleteItem(id) {
      this.billboard_id = id;
      this.deleteDialog = true;
      this.loading = true;
    },

    deleteBbConfirm() {
      this.deleteBB(this.billboard_id);
      this.closeDelete();
      this.loading = false;
    },

    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toDateString();
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style scoped>
.v-card {
  font-family: Manrope;
}

.v-data-table__expanded__content {
  background-color: antiquewhite;
}
.image {
  height: 50px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
.image:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
.actions--icon {
  padding: 0 5px;
}
.actions--icon:hover {
  transform: scale(1.2);
  transition: all 0.2s;
}
.v-btn {
  margin: 15px 0;
  text-transform: none;
}
</style>
